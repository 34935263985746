<template>
  <v-card outlined color="#e8eaf6" class="mt-1 pa-1" @click="isList ? navigateOrder(model.id) : false">
    <v-card-text class="pa-1 pt-0 pb-0">
      <v-row>
        <v-col cols="3" class="mt-3 pa-0 pl-1">
          <span class="body-2">{{ model.number }}</span>
        </v-col>
        <v-col cols="7" class="pa-0">
          <v-row class="mt-0">
            <v-col cols="12" class="pa-0">
              <template v-if="type === 'supplier'">
                {{ model.suppliername }}
              </template>
              <template v-else-if="type === 'transfer'">
                S: {{ model.sourcewarehouse }}
              </template>
              <template v-else-if="type === 'adjustment'">
                W: {{ model.warehousename }}
              </template>
            </v-col>
            <v-col cols="12" class="pa-0" v-if="type === 'transfer'">
              D: {{ model.destwarehouse }}
            </v-col>
            <v-col cols="7" class="pa-0">
              O: {{ model.orderdate | dateFormat }}
            </v-col>
            <v-col cols="5" class="pa-0 text-end pr-3">
              {{ $t('message.product.qty') }}:
              <template v-if="type === 'supplier'">{{ model.receivedqty | absoluteNumber }}</template>
              <template v-else-if="type === 'adjustment'">{{ model.qty | absoluteNumber }}</template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="mt-2 pa-0 pr-2 text-right">
          <v-btn icon v-if="isList">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <h4 v-else>
            +{{ model.receivedqty | absoluteNumber }}
          </h4>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    isList: {
      type: Boolean
    },
    model: {
      type: Object
    },
    type: {
      type: String
    }
  },
  methods: {
    navigateOrder (id) {
      let url = ''
      switch (this.type) {
        case 'supplier':
          url = 'supplier_orders'
          break
        case 'transfer':
          url = 'transfer_orders'
          break
        case 'adjustment':
          url = 'adjustment_orders'
          break
        default:
          break
      }
      this.$router.push(`/${url}/${id}`)
    }
  }
}
</script>
